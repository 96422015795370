import { ButtonModel, Link } from "../Events/EventList/EventList"
import { generateGuidV4 } from "../../common/helper/helper"
import { BtNGroup } from "../../common/Components/Button"
import Video from "../Video/Components/Video"
import * as React from "react"

interface ICardProps {
    media: IMedia
}

type CarouselMediaType = "Image" | "Video"

export interface IMedia {
    MediaType: CarouselMediaType
    SrcUrl: string
    CardContent?: string
    Buttons?: ButtonModel[],
    Link?: Link,
    ButtonsAlignment?: string
}

interface ICarouselElementFactoryProps {
    //isFullScreenImageMode: boolean
    media: IMedia
    carouselId: string
    key: number
    mode: CarouselMode
}

const MediaContentFactory = (props: IMedia) => {
    if (props.MediaType && props.SrcUrl) {
        switch (props.MediaType) {
            case "Video":
                return <Video videoId={generateGuidV4()} videoUrl={props.SrcUrl}
                    isAutoPlay isLoop isMuted isShowControls={false} width={400} height={288} />
            case "Image":
                return <img className="carousel-image" src={props.SrcUrl} />
            default:
                return <img className="carousel-image" src={props.SrcUrl} />
        }
    }
    else {
        return <></>
    }
}

const CarouselImage: React.FC<ICarouselElementFactoryProps> = props => <div className="carousel-image-wrapper">
    <img
        className="carousel-image"
        src={props.media.SrcUrl}
    />
    {/* Not apply purifyHtml here because it is the content past down from the rich text editor in the BackOffice UI */}
    <div className="content-centered" dangerouslySetInnerHTML={{ __html: props.media.CardContent }} />
</div>

const CarouselCard: React.FC<ICarouselElementFactoryProps> = props => <div className="carousel-child" key={`${props.carouselId}-card-${props.key}-parent`}>
    <Card key={`${props.carouselId}-card-${props.key}`} media={props.media} />
</div>

const TextOverImageCard: React.FC<ICardProps> = (props) => {
    console.log("media", props.media)
    return <div className="card">
        <img className="card-img" src={props.media.SrcUrl} alt="Bologna" />
        <div className="card-img-overlay text-white d-flex flex-column justify-content-center">
            {props.media.CardContent && <div className="card-body">
                {/* Not apply purifyHtml here because it is the content past down from the rich text editor in the BackOffice UI */}
                <div className="card-content" dangerouslySetInnerHTML={{ __html: props.media.CardContent }} />
            </div>}
            {props.media.Buttons && props.media.Buttons.length > 0 && <BtNGroup className={props.media.ButtonsAlignment} buttons={props.media.Buttons} />}
        </div>
    </div >
}

const Card: React.FC<ICardProps> = (props) => (
    <a href={props.media.Link && props.media.Link.Url} target={props.media.Link && props.media.Link.Target} className="card">
        <MediaContentFactory SrcUrl={props.media.SrcUrl} MediaType={props.media.MediaType} />
        {props.media.CardContent && <div className="card-body">
            {/* Not apply purifyHtml here because it is the content past down from the rich text editor in the BackOffice UI */}
            <div className="card-content" dangerouslySetInnerHTML={{ __html: props.media.CardContent }} />
        </div>}
        {props.media.Buttons && props.media.Buttons.length > 0 && <BtNGroup className={props.media.ButtonsAlignment} buttons={props.media.Buttons} />}
    </a>
)

export enum CarouselMode {
    CARD = "card",
    FULL_WIDTH_IMAGE = "full width image",
    TEXT_OVER_IMAGE = "text over image"
}

export const CarouselElementFactory: React.FC<ICarouselElementFactoryProps> = props => {
    switch (props.mode?.toLocaleLowerCase()) {
        case CarouselMode.FULL_WIDTH_IMAGE:
            return <CarouselImage {...props} />
        case CarouselMode.TEXT_OVER_IMAGE:
            return <TextOverImageCard {...props} />
        default:
            return <CarouselCard {...props} />
    }
}