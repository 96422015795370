import * as React from "react";
import { ButtonModel } from "components/Events/EventList/EventList";

interface IButtonGroupProps {
    buttons: ButtonModel[]
    className?: string
}

export const BtNGroup = (props: IButtonGroupProps) => (
    props.buttons && props.buttons.length && <div className={`bottom-btn-group ${props.className}`}>
        {props.buttons.map((b: ButtonModel, i: number) => <div key={i} id={b.ButtonId} className={`${b.ColorLabel && b.ColorLabel.toLowerCase()}-btn bottom-btn btn ${b.Shape}`}>
            <a href={b.Link && b.Link.Url} target={b.Link && b.Link.Target}>{b.Text}</a>
        </div>)}
    </div>
)
